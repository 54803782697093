<template>
  <div class="cishu_warp">
    <a-tabs v-model:activeKey="activeName"
            centered>
      <a-tab-pane :tab="item.subject_name"
                  v-for="(item) in subjectList"
                  :key="item.subject_id+''">
        <div class="txt">
          <div>1.{{summary.text1}} </div>
          <div>2.{{summary.text2}} </div>
        </div>
      </a-tab-pane>

    </a-tabs>
    <div style="display: flex; justify-content: center;margin-top: 100rem;">
      <a-button @click="getPdfReport(item)">
        导出{{ subjectList.find(item => item.subject_id == activeName)?subjectList.find(item => item.subject_id == activeName).subject_name:'' }}电子教辅
      </a-button>
    </div>
    <FormDialog ref="FormRef"></FormDialog>
  </div>
</template>

<script setup>
import { ref, reactive, inject, watch, nextTick } from 'vue'
import FormDialog from "./components/formDialog.vue"
import { instance } from '@/utils/http.js'
let value1 = inject('value1')
let value2 = inject('value2')
let subjectList = inject('subjectList')
let activeName = ref(0)
let userInfo = ref(null)
let FormRef = ref(null)
let summary = ref({})
watch(activeName, () => {
  getInfo()
})
const getInfo = async () => {
  if (activeName.value == 0) {
    return
  }
  const { data } = await instance({
    url: '/api/v1/user/learning_analysis',
    method: 'get',
    params: {
      subject_id: activeName.value,
      this_weak_start_time: value1 ? value1[0] : '',
      this_weak_end_time: value1 ? value1[1] : '',
    }
  })
  summary.value = data.user_subject_conclusion

}
const getPdfReport = async () => {
  let params = {
    subject_id: activeName.value,
    first_begin_time: value1 ? value1.value[0] : '',
    first_end_time: value1 ? value1.value[1] : '',
    second_begin_time: value2 ? value2.value[0] : '',
    second_end_time: value2 ? value2.value[1] : '',

  }

  userInfo.value = JSON.parse(window.localStorage.getItem('userInfo'))
  FormRef.value.params = params
  FormRef.value.userInfo = userInfo
  FormRef.value.btnName = subjectList.value.find(item => item.subject_id == activeName.value)?subjectList.value.find(item => item.subject_id == activeName.value).subject_name :''
  FormRef.value.dialogVisible = true

}
nextTick(() => {
  getInfo()
})
defineExpose({
  getInfo, activeName
})
/*export default {
  data () {
    return {
      subjectList: [],
      activeName: 0,
      summary: {}
    }
  },
  mounted () {

  },
  watch: {
    activeName () {
      this.getInfo()
    }
  },
  methods: {
    getInfo () {
      if (this.activeName == 0) {
        return
      }
      this.$http({
        url: '/api/v1/user/learning_analysis',
        method: 'get',
        params: {
          subject_id: this.activeName,
          this_weak_start_time: this.$parent.value1 ? this.$parent.value1[0] : '',
          this_weak_end_time: this.$parent.value1 ? this.$parent.value1[1] : '',
        }
      }).then(res => {
        this.summary = res.data.user_subject_conclusion
      })
    },
    handleClick (e) {
      console.log(e.index);
    },
  }
}*/
</script>

<style lang="scss" scoped>
.cishu_warp {
}
:deep .ant-tabs-tab {
  padding: 12px 8px;
}
:deep .ant-btn {
  border-radius: var(--radius-100_max, 100px);
  border: 1px solid var(--neutral-color-999999, #999);
  display: flex;
  height: var(--iconsize-size_xl, 40px);
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.txt {
  padding: 0 84rem;
  font-size: 24rem;
  line-height: 2em;
}
:deep .ant-tabs-tab {
  padding: 12rem;
  font-size: 24rem;
  margin: 0 30rem;
}
</style>