<template>
  <div>
    <a-modal v-model:visible="dialogVisible"
             @ok="exportBtn"
             :width='480'
             title="请选择"
             :bodyStyle="{display:'flex'}"
             @cancel="handleClose">
      <a-form :model="form"
              size="medium"
              ref="formRef">
        <a-form-item>
          薄弱考点数量
          <a-input-number id="inputNumber"
                          v-model:value="point_num"
                     
                          :min="1"
                          :max="10" />
        </a-form-item>
        <a-form-item>
          各考点推送题目数量
          <a-input-number id="inputNumber"
                          v-model:value="question_num"
                        
                          :min="1"
                          :max="20" />
        </a-form-item>
      </a-form>

    </a-modal>
  </div>
</template>

<script>

import { getReport } from '@/api/subject.js'



export default {
  data () {
    return {
      dialogVisible: false,
      cityOptions: [],
      form: {
      },
      params: {},
      select_all: 0,
      question_num: 5,
      point_num: 5,
      userInfo: {},
      btnName: ''
    }
  },
  async created () {

  },
  methods: {

    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    base64ToBlob (base64) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: 'application/octet-stream' });
    },
    async exportBtn () {
      this.params.point_num = this.point_num
      this.params.question_num = this.question_num
      console.log('sssssssss', this.userInfo);

      await getReport(this.params).then(res => {
        const a = document.createElement("a")
        const blob = this.base64ToBlob(res.data.data)
        a.href = window.URL.createObjectURL(blob)
        console.log('this', this.userInfo.nickname);

        a.download = `${this.userInfo.nickname}${this.btnName}电子教辅.pdf`
        a.click()
      })
      this.handleClose()

    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-form-item-control-input-content {
  width: 361px;
  margin-left: 24px;
    display: flex;
    justify-content: space-between;
}
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 16rem;
  // padding: 27rem;
  padding-top: 0;
}
.btn_warp {
  width: 720rem;
  height: 62rem;
  border-top: 1rem solid #eeeeee;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0rem 0rem 0rem 0rem;
  opacity: 1;
  align-items: center;
  justify-content: center;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 24rem;
    &:nth-child(1) {
      width: 40rem;
      height: 60rem;
      font-size: 20rem;

      font-weight: 400;
      border-right: 1rem solid #eeeeee;
      color: #333333;

      line-height: 30rem;
      -webkit-background-clip: text;
    }
    &:nth-child(2) {
      width: 40rem;
      height: 30rem;

      font-size: 20rem;

      font-weight: 400;
      color: #2196f3;
      line-height: 30rem;
      -webkit-background-clip: text;
    }
  }
}
</style>